// LMS Wrapper HOC
div#LMS-Wrapper {
  position: relative;
  height: auto;
  max-width: calc(100% - 400px);
  margin-left: 370px;
  padding: 0 !important;
  padding-bottom: 3rem !important;

  .s-dahsboard-d-left,
  .s-dahsboard-d-right {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }

  .s-dahsboard-d-left {
    position: fixed;
    left: -5rem;
    bottom: -5rem;
    z-index: -1;
  }

  .s-dahsboard-d-right {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}

@media screen and (max-width: 575px) {
  div#LMS-Wrapper {
    margin: auto !important;
    min-width: calc(100% - 30px) !important;
  }
}