div#admin-sidebar {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	background: #FBFBFB; 
	padding: 2rem 0rem;
	width: 275px;
	z-index: 9999 !important;
	box-shadow: 9px 0px 74px rgba(0, 0, 0, .02);
	border-right: 1px solid #EFEFEF;
	overflow-y: auto;
	overflow-x: hidden;

	img {
		max-width: 150px;
	}

	ul {
		list-style-type: none;

		li {
			padding: 0.7rem 0;
		}
	}

	#sidebar-link {
		opacity: .35 !important;
	}

	.sidebar-nav-link {
		color: var(--text-color);
		text-decoration: none;

		li {
			opacity: .75;

			&:hover {
				opacity: 1;
			}
		}

		li.active {
			opacity: 1;
			font-weight: bold;
			.active-mark {
				width: 3px;
				background-color: #007bff;
			}
		}

		span {
			margin-left: 0.5rem;
		}
	}

	.sidebar-icon {
		position: relative;
		bottom: 0.15rem;
	}

	.active {
		opacity: 1;
	}

	.sidebar-decor {
		position: absolute;
		bottom: 0;
		left: 0;
		min-width: 245px;
	}

	.sidebar-bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 0.5rem 1.5rem;
		width: 275px;
	}

	div.acc-info {
		margin-bottom: 1rem;

		p {
			margin: 0 auto;
		}
	}

	// Temporary Disabled Links
	.disabled {
		li {
			opacity: .35 !important;
		}
	}
}

#SidebarWrapper {

	// Sidebar Toggler
	div#sidebar-toggler {
		position: fixed !important;
		z-index: 99999 !important;
		cursor: pointer;
		display: none;
	}
}

@media (max-height: 768px) {
	div#admin-sidebar .sidebar-bottom {
		position: unset;
	}
}