div#Navbar {
    nav {
        background: var(--primary-color) !important;
        padding: 1.2rem 0;

        div {
            img {
                max-width: 175px;
            }
        }
    }

    #search-lms {
        div.input-group-prepend {
            span {
                border-radius: 0;
                background: transparent;
                border: none;
                border-bottom: 1px solid #EFF0F2;
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 0.75rem;

                img {
                    position: relative;
                    bottom: 0.10rem;
                }
            }
        }

        input {
            max-width: 95%;
            background: transparent;
            border: none;
            border-bottom: 1px solid #EFF0F2;
            border-radius: 0;
            padding-bottom: 1rem;

            &:focus {
                border: none !important;
                border-bottom: 1px solid #EFF0F2 !important;
            }
        }
    }

    #notification {
        position: relative;
        top: 0.30rem;
    }

    #avatar_dropdown {
        border-radius: 50px !important;
        margin-left: 0.4rem;
        padding: 0 !important;
        background: #F4F5F5 !important;
        color: var(--text-color) !important;
        box-shadow: 10px 10px 16px rgba(0, 0, 0, .05) inset, -6px 7px 18px rgba(0, 0, 0, .02) !important;
        border: none !important;
        width: 40px;
        height: 40px;

        .avatar-icon {
            width: 40px;
            height: 40px;
            border-radius: 50% !important;
            font-size: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    div.dropdown-menu {
        .dropdown-item-text:hover {
            background-color: #e9ecef;
        }

        a.nav-link {
            padding: 0;
        }
    }

    div.dropdown {
        padding: 0;
    }

    .nav-item-group {
        display: flex;
    }

    .nav-mobile {
        color: rgba(0, 0, 0, .5);
        border: 1px solid transparent;
        border-radius: .25rem;
        border-color: rgba(0, 0, 0, 0.1);
        margin-left: 1rem;
        display: none;

        .nav-mobile-icon {
            cursor: pointer;
            font-size: 2.3rem;
            padding: .25rem .4rem;
            line-height: 1;

            svg {
                margin-top: -8px;
            }
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
    div#Navbar {
        .nav-mobile {
            display: block;
        }
    }
}
