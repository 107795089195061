.table-container {
  margin: 10px;
  padding: 0;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

tr.expandable>td {
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, .2);
  padding: 0;
}

tr.expandable>td>.inner {
  margin: 45px 75px;
  overflow: hidden;
}

.uk-background-muted {
  padding: 0px !important;
}

.uk-table-header {
  background: #e9ecef;
  color: #495057;
}

.collapse-row:hover {
  background-color: #f0f5f9;
}

.expander-child-cell {
  display: flex;
  margin-left: 20px;
  align-items: flex-start;
  flex-direction: column;

  .expander-child-label {
    flex-basis: 25%;
    font-weight: bold;
  }
  
  .expander-child-content {
    flex-basis: 75%;
    font-weight: bold;
    color: grey;
    font-size: 20px;
    word-break: break-word;
  }
}

.expander-analytics-link {
  margin-left: calc(50% + 80px);
  margin-top: 20px;

  a {
    color: grey;
    display: flex;
    align-items: center;

    span {
      padding-left: 5px;
    }
  }
}



.descending {
  display: flex;
  align-items: center;
}

.ascending {
  display: flex;
  align-items: center;
}

.uk-table-icon {
  width: 100%;
  display: flex;
  place-content: flex-end;
}

.uk-table-cell {
  display: flex;
  align-items: center;
  width: 100%;
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .expander-analytics-link {
    margin-left: calc(65% + 50px);
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .expander-analytics-link {
    margin-left: calc(70% + 35px);
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .expander-child-label {
    flex-basis: 40%;
  }

  .expander-child-content {
    flex-basis: 60%;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .expander-child-label {
    flex-basis: 60%;
  }

  .expander-child-content {
    flex-basis: 40%;
  }

  .expander-analytics-link {
    margin-left: calc(60% + 30px);
  }
}

@media screen and (max-width: 575px) {
  .expander-child-label {
    flex-basis: 80%;
  }

  .expander-child-content {
    flex-basis: 20%;
  }

  tr.expandable>td>.inner {
    margin-right: 0px;
    margin-left: 0px;
    overflow: hidden;
  }

  .expander-analytics-link {
    margin-left: 20px;
  }
}