div#Navigation {
    .i-arrow {
        margin-left: 0.3rem;
    }
    nav {
        background: var(--primary-color) !important;
        // border-bottom: 1px solid #e9e9e9;
        padding: 1.2rem 3rem;
        div {
            img {
                max-width: 175px;
            }
        }
    }
    #avatar_dropdown {
        border-radius: 50px !important;
        margin-left: 0.4rem;
        padding: 0 !important;
        background: #F4F5F5 !important;
        color: var(--text-color) !important;
        box-shadow: 10px 10px 16px rgba(0, 0, 0, .05) inset, -6px 7px 18px rgba(0, 0, 0, .02) !important;
        border: none !important;
        width: 40px;
        height: 40px;

        .avatar-icon {
            width: 40px;
            height: 40px;
            border-radius: 50% !important;
            font-size: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    div.dropdown-menu {
        a.nav-link {
        padding: 0;
        }
    }
}