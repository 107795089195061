.modalContainer {
    width: 400px !important;

    & input:focus {
        border: none !important;
    }

    & .modal-body {
        display: flex;
        justify-content: center;
        flex-direction: column;

        & .btn {
            padding: 10px !important;
            outline: none;
            border: none;
            border-radius: .25rem;
            font-weight: 600;
            width: 100%;
            margin: 16px 0px;
        }

        .enroll-modal-plus {
            margin: 0px;
            padding: 0px !important;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            visibility: hidden;
        }
    }

    .enroll-modal-header {
        font-size: 26px;
    }

    .enroll-modal-learners-container {
        height: 300px;

        .enroll-modal-learners {
            width: 50%;
            height: 100%;
            overflow: auto;
        }

        .enroll-modal-enroll-learners {
            width: 50%;
            height: 100%;
            overflow: auto;
        }
    }


    .learners-element {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 4px;

        &:hover {
            background-color: #eee;

            .enroll-modal-plus {
                visibility: visible;
            }
        }

        .learners-element-data {
            display: flex;
            flex-direction: column;
            width: 85%;

            .learners-element-email {
                word-break: break-word;
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .learners-element-button {
            width: 15%;
            display: flex;
            justify-content: center;
        }
    }
}
