.course-row {
  &:hover {
    background-color: #eee;
    cursor: auto;

    .remove-col {
      visibility: inherit;
    }
  }

  .email-col {
    display: flex;
    justify-content: space-between;
    border: none !important;
  }

  .remove-col {
    color: grey;
    justify-content: space-between;
    cursor: pointer;
    visibility: hidden;
  }
}