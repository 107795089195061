div#Login {
    img {
        max-width: 175px;
    }
   div.jumbotron-wrapper {
       display: flex;
       justify-content: center;
       align-items: center;
       height: 50vh;
       margin-top: 14rem;
    div.jumbotron {
       background: #fff;
       box-shadow: 0 0 5px rgba(0,0,0,.15);
       border-radius: 5px;
       padding: 2rem 3rem;
       min-width: 475px;
    }
   }
}