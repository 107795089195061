.summary-area-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 20px;
}

.summary-title {
  font-weight: bold;
}

.summary-text-container {
  min-height: 120px;
}

.summary-section {
  display: flex;
}

.summary-subsection-wrapper {
  width: 50%;
}

.summary-subsection {
  display: flex;
}

.summary-area-list {
  padding-left: 25px;
}

.divider-line {
  border-left: 1px solid #F4F4F4;
}

.summary-subsection-wrapper-2 {
  display: flex;
  .summary-area-wrapper {
    width: 100%;
    margin-left: 80px;
  }
}

.summary-value {
  color: grey;
  font-size: 20px;
  font-weight: bold;
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .summary-subsection-wrapper {
    width: 65%;
  } 

  .summary-subsection-wrapper-2 .summary-area-wrapper{
    margin-left: 50px;
  }

  .summary-text-container {
    min-height: 155px;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .summary-subsection-wrapper {
    width: 60%;
  }

  .expandable .summary-subsection-wrapper {
    width: 70%;
  }

  .expandable .summary-subsection-wrapper-2 {
    .summary-area-wrapper {
      margin-left: 35px;
    }
  } 

  .summary-text-container {
    min-height: 155px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .summary-subsection-wrapper {
    width: 70%;
  } 

  .summary-subsection-wrapper-2 .summary-area-wrapper{
    margin-left: 30px;
  }

  .summary-text-container {
    min-height: 210px;
  }
}

@media screen and (max-width: 575px) {
  .summary-section {
    flex-direction: column;
  }

  .divider-line {
    border-top: 1px solid #f4f4f4;
    margin: 1rem 0;
  }

  .summary-subsection-wrapper {
    width: 100%;
  }

  .collapsible-table {
    tbody tr, tbody td, tbody th {
      padding: 1rem 0.5rem !important;
    }

    thead tr, thead td, thead th {
      padding: 1rem 0.5rem !important;
    }
  }

  .summary-subsection-wrapper-2 .summary-area-wrapper{
    margin-left: 20px;
  }

  .expandable {
    .summary-subsection {
      flex-direction: column;
    }

    .expander-child-cell {
      flex-direction: column;
      align-items: flex-start;
    }

    .summary-subsection-wrapper-2 .summary-area-wrapper {
      margin-left: 20px;
    }
  }

  .summary-text-container {
    min-height: 290px;
  }
}