// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body,
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.menu {
    font-size: 14px;
    background-color: #fff;
    border-radius: 2px;
    padding: 5px 0px;
    height: auto;
    margin: 0;
    /* use absolute positioning  */
    position: absolute;
    list-style: none;
    box-shadow: 3px 3px 4px 0 grey;
    opacity: 1;
    transition: opacity 1s linear;
    cursor: pointer;
    z-index: 2;
}

.contextMenu {
    padding: 0px 10px;

    &:hover {
        background-color: #f5f5f5;
    }
}

.input-field {
    margin-bottom: 12px;
}

.card-input {
    margin-top: 10px;
    border-radius: 5px;
    height: 78px;
    width: 100%;
    border: 1px solid #E7EAEC;
    font-weight: 300;
    font-size: 14px;
    color: #000;
    resize: none;
    padding: 14px 14px 14px 14px;
    box-sizing: border-box;
}

.card-input-choice {
    margin-top: 10px;
    border-radius: 5px;
    height: 52px;
    width: 100%;
    border: 1px solid #E7EAEC;
    font-weight: 300;
    font-size: 14px;
    color: #000;
    resize: none;
    padding: 14px 14px 14px 14px;
    box-sizing: border-box;
}

.card-input:focus {
    outline: none !important;
    box-shadow: inset 0 0 0 2px #007bff;
}



label {
    color: #69708A;
    font-weight: 500;
    font-size: 14px;
}

div.mobile-sidebar {
    display: none;
}

// Responsiveness
// Mobile
@media (max-width: 600px) {

    // Hero
    div#Hero {
        text-align: center;
        background-position: 0 0 !important;
        margin-top: 12rem;
        height: 50vh !important;

        h1 {
            font-size: 40px;
        }

        a {
            font-size: 14px;
        }
    }

    // Navigation
    div#Navigation {
        nav {
            padding: 1rem 1rem !important;
        }

        #avatar_dropdown {
            margin: 0 !important;
        }
    }

    ul>li>a {
        margin-top: 1.5rem;
        padding: 0.3rem 1rem !important;
    }

    div.dropdown {
        padding: 1rem;
    }

    // My Account
    div#myAccount {
        margin-top: 11rem !important;

        div.row {
            margin-top: 0 !important;
        }

        div#student-account,
        div#student-grades {
            margin-top: 2rem;
        }
    }

    // Login/Signup
    div#Login,
    div#Signup {
        div.jumbotron-wrapper {
            div.jumbotron {
                min-width: 90% !important;
                max-width: 90%;
            }
        }
    }

    // Quiz
    div#quiz {
        margin-top: 12rem;

        div.Quiz-Intro {
            padding: 0;
        }

        div.start-quiz-btns,
        div.end-quiz-btns {
            a {
                margin: 1rem 0;
            }
        }

        div.end-quiz-btns {

            a,
            button {
                font-size: 14px;
            }
        }

        div#quiz-options {
            margin: 3rem auto !important;
            min-width: 100% !important;
            max-width: 100% !important;
        }

        div.alert {
            max-width: 100%;
        }
    }

    // LMS
    div.Article {
        padding: 0 1rem;
    }
}


// Global Stylesheet
:root {
    --primary-color: #F8F9FA; //#FEFEFF #f9f9f9 //F8F9FA //#F4F8FB
    --secondary-color: #2068F8; //#2068F8 #7754F8 #5666F5

    --secondary-fade: #F1EDFF;
    --secondary-btn: #EEEEEE;
    --text-color: #282828; //#282828 #0A0F34
    --subtext-color: #7B8288;
    --white-color: #ffffff;

    // Inputs
    --input-background: #FAF9FC;
    --input-border: #EBEAEE;

    // Alerts
    --alert-danger: #FF6363; //#FF6363 #FF7878
    --alert-success: #38AC81; //#24C28C
}

// Global Settings
.no-user-select {
    user-select: none;
    --webkit-user-select: none;
    --ms-user-select: none;
    --o-user-select: none
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.uppercase {
    text-transform: uppercase;
}

.fs-12 {
    font-size: 12px !important;
}

.fw-regular {
    font-weight: 500 !important;
}

// Badges
.badge-success {
    background-color: var(--alert-success) !important;
}

.badge-danger {
    background-color: var(--alert-danger) !important;
}

// Tables
thead {

    tr,
    td,
    th {
        padding: 1rem 2rem !important;
        border: 1px solid #F0F0F1 !important;
    }
}

tbody {

    tr,
    td,
    th {
        padding: 1rem 2rem !important;
        border: 1px solid #F0F0F1 !important;
    }

}

.tableFixHead {
    overflow: auto;
    height: 60vh;
}

.tableFixHead thead th {
    position: sticky;
    top: -1px;
    z-index: 1;
    padding: 18px !important;
    height: 12px;
    text-align: center;
}

/* Just common table stuff. Really. */
table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    padding: 8px 16px;
}

// Text Colors, Alerts, Buttons & Inputs
.subtext-color {
    color: var(--subtext-color);
}

.txt-alert-danger {
    color: var(--alert-danger)
}

.txt-alert-success {
    color: var(--alert-success)
}

body {
    background: var(--primary-color) !important;
}

.btn {
    padding: 1rem 2rem !important;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, .15) !important;

    // border-radius: 8px !important;
}

.btn-primary {
    background: var(--secondary-color) !important;
    box-shadow: -6px 7px 18px rgba(0, 0, 0, .11) !important;
    color: var(--white-color) !important;
    transition: .35s !important;

    &:hover {
        opacity: .75;
        transition: .35s !important;
    }
}

.btn-primary-hero {
    background: var(--secondary-color) !important;
    box-shadow: 10px 10px 16px rgba(0, 0, 0, .16) inset, -6px 7px 18px rgba(0, 0, 0, .11) !important;
    color: var(--white-color) !important;
}

.btn-secondary {
    background: #F4F5F5 !important;
    color: var(--text-color) !important;
    box-shadow: 10px 10px 16px rgba(0, 0, 0, .05) inset, -6px 7px 18px rgba(0, 0, 0, .02) !important;
    transition: .35s !important;

    &:hover {
        box-shadow: 10px 10px 16px rgba(0, 0, 0, .12) inset, -6px 7px 18px rgba(0, 0, 0, .02) !important;
        transition: .35s !important;
    }
}

.btn-danger {
    background: var(--alert-danger) !important;
    transition: .35s !important;

    &:hover {
        opacity: .75;
        transition: .35s !important;
    }
}

.btn-secondary-ins-shadow-none {
    background: #F4F5F5 !important;
    color: var(--text-color) !important;
    box-shadow: -6px 7px 18px rgba(0, 0, 0, .02) !important;
}

.btn-secondary-grey-variation-1 {
    color: var(--subtext-color);
    background: #F4F8FB !important;
}

.btn-rounded-sm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    border-radius: 50px !important;
}

.btn-td {
    padding: 5px 15px !important;
}

.btn-blob {
    border-radius: 50px !important;
    border-top-left-radius: 0 !important;
}

.btn-blob-p-2 {
    border-radius: 50px !important;
    border-top-left-radius: 0 !important;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.p-1-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.badge-secondary {
    color: var(--subtext-color) !important;
    background: #F4F5F5 !important;
    box-shadow: 10px 10px 16px rgba(0, 0, 0, .05) inset, -6px 7px 18px rgba(0, 0, 0, .02) !important;
    padding: 0.5rem 1rem;
    font-size: 14px;
}

.blob {
    border-radius: 50px !important;
    border-top-left-radius: 0 !important;
}

.btn-secondary-fade {
    border-radius: 50px !important;
    border-top-left-radius: 0px !important;

    background: var(--secondary-fade) !important;
    color: var(--secondary-color) !important;

    &:hover {
        background: var(--secondary-color) !important;
        color: var(--white-color) !important;
    }
}

input {
    // border: 1px solid var(--input-border) !important;
}

input:focus {
    box-shadow: none !important;
    outline: 0 !important;
    border: 1px solid rgba(0, 0, 0, .30) !important;
}

input::placeholder {
    opacity: .7 !important;
}

div.breadcrumb {
    padding: 0;
    background: none !important;

    a {
        text-decoration: underline;
        color: var(--text-color);
    }
}

.list-group-item.active {
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
}

.list-group-item {
    padding: 1rem 1.5rem !important;
}

.pointer {
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
}

.divider {
    height: 1px;
    width: calc(100% - 0.5rem);
    margin: 1rem 0;
    background: #F4F4F4;
}



// QUIZ
.qa {
    p.subtext-color {
        font-size: 16px;
    }
}

#quiz {

    // margin-top: 14rem;
    .qa-wrapper {
        background: var(--primary-color);
        border-radius: 14px;
        border: 1px solid #E9E9E9;
        padding: 3rem;

        p.subtext-color {
            font-size: 16px;
        }
    }

    #quiz-options {
        span#option-index {
            font-size: 18px;
            font-weight: bold;
            width: 58px;
            min-width: 58px;
            text-align: center;
            padding: 24px 0px;
        }

        button {
            border-left: 1px solid #ECECEC;
            overflow-wrap: break-word;
            word-break: break-word;
            width: calc(100% - 58px);
        }
    }
}

div.divider-v {
    height: 100% !important;
    width: 2px;
    background: red;
}

div.start-quiz-btns>* {
    margin: 0 0.5rem;
}

div.end-quiz-btns>* {
    margin: 0 0.5rem;
}

@keyframes Loader {
    0% {
        transform: translateY(-1rem) scale(1);
        opacity: .75;
    }

    100% {
        transform: translateY(0.5rem) scale(0.50);
    }
}

@keyframes LoaderIN {
    0% {
        transform: translateY(1rem) scale(0.54);
        opacity: .50;
    }

    100% {
        transform: translateY(0.25rem) translateX(-3rem) scale(0.55);
        opacity: .3;
    }
}

.loader-none {
    min-height: 51vh;
}

.alert {
    font-size: 20px;
}

#quiz-end {
    .alert {
        max-width: 50%;
        // margin: 0 auto;
    }
}

box-icon[name="check"] {
    position: relative;
    top: 0.25rem;
}

box-icon[name="x"] {
    position: relative;
    top: 0.34rem;
}

box-icon[name="time-five"] {
    position: relative;
    top: 0.40rem;
}

// Error Component
div#Error {
    margin-top: 12rem;
    min-height: 50vh;
}

// Errick Demo v2.0 Responsiveness
.icon-toggle {
    display: none;
}

.sidebar-toggle {
    display: none;
}

// Laptop Devices
@media screen and (min-width: 1024px) and (max-width: 1506px) {

    // Sidebar
    div#student-sidebar {
        ul {
            margin-top: 1.5rem !important;
        }

        div.divider {
            margin: 0.25rem !important;
        }

        padding: 2rem 1.5rem;
        width: 250px !important;
    }

    div#admin-sidebar {
        div.divider {
            margin: 0.25rem !important;
        }

        padding: 2rem 1.5rem;
        width: 250px !important;
    }

    // LMS Wrapper Sections
    div#LMS-Wrapper {
        height: auto !important;
        max-width: calc(100% - 350px) !important;
        width: calc(100% - 350px) !important;
        margin-left: 305px !important;
        padding: 0 !important;
        padding-bottom: 3rem !important;
    }


    div#LMS div.lms-cards div.card {
        width: 300px;
    }
}

// Tablet Devices
@media screen and (min-width: 0px) and (max-width: 1024px) {

    // Sidebar
    div#student-sidebar,
    div#admin-sidebar {
        ul {
            margin-top: 1.5rem !important;
        }

        div.divider {
            margin: 0.25rem !important;
        }

        padding: 2rem 1.5rem;
        width: 250px !important;
    }

    // LMS Wrapper Sections
    div#LMS-Wrapper {
        height: auto;
        max-width: calc(100% - 100px) !important;
        width: calc(100% - 70px) !important;
        margin-left: 50px !important;
        padding: 0 !important;
        padding-bottom: 3rem !important;
    }


    div#LMS div.lms-cards div.card {
        width: 300px;
    }
}

@media screen and (min-width: 0px) and (max-width: 575px) {

    // LMS Wrapper Sections
    div#LMS-Wrapper {
        max-width: calc(100% - 30px) !important;
        width: calc(100% - 30px) !important;
    }

    #quiz {
        .qa-wrapper {
            padding: 1rem;
        }
    }
}


// Sidebar & Other
@media (max-width: 1024px) {

    div.container {
        min-width: 95%;
    }

    div.Article {
        padding-right: 0 !important;
    }

    div#sidebar-toggler {
        position: fixed;
        z-index: 99999;
        max-width: 60px;
        transform: translate(2rem, 0.25rem);
        display: block !important;
    }

    div#search-lms {
        display: none;
    }

    div.qa-wrapper {
        // padding: 0 !important;
    }

    div.desktop-sidebar {
        display: none;
    }

    .menuBack {
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 2;
        background-color: grey;
        opacity: 0.5;
    }

    div.mobile-sidebar {
        display: block;
        transition: 0.5s;



        .showDisable {
            display: none;
        }
    }
}
