.time-bar-container {
  width: 400px;
  margin: 50px;
  margin-top: 25px;
  .rc-slider {
    position: relative;
    height: 14px;
    padding: 5px 0;
    width: 100%;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  
    &-rail {
      position: absolute;
      width: 100%;
      background-color: #e9e9e9;
      height: 4px;
      border-radius: 6px;
    }
  
    &-track {
      position: absolute;
      left: 0;
      height: 4px;
      border-radius: 6px;
      background-color: rgba(45, 183, 245, 0.6);
    }
  
    &-handle {
      position: absolute;
      width: 14px;
      height: 14px;
      cursor: pointer;
      cursor: -webkit-grab;
      margin-top: -5px;
      cursor: grab;
      border-radius: 50%;
      border: solid 2px rgba(45, 183, 245, 0.5);
      background-color: #fff;
      touch-action: pan-x;
     
      &:focus {
        outline: none;
      }
    
      &-click-focused:focus {
        border-color: rgba(45, 183, 245, 0.5);
        box-shadow: unset;
      }
    
      &:hover {
        border-color: rgba(45, 183, 245, 0.2);
      }
    
      &:active {
        border-color: rgba(45, 183, 245, 0.2);
        box-shadow: 0 0 5px rgba(45, 183, 245, 0.2);
        cursor: -webkit-grabbing;
        cursor: grabbing;
      }
    }
  
    &-mark {
      position: absolute;
      top: 18px;
      left: 0;
      width: 100%;
      font-size: 12px;
    }
  
    &-mark-text {
      position: absolute;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      color: #999;
  
      &-active {
        color: #666;
      }
    }
  
    &-step {
      position: absolute;
      width: 100%;
      height: 4px;
      background: transparent;
    }
  
    &-dot {
      position: absolute;
      bottom: -2px;
      margin-left: -4px;
      width: 8px;
      height: 8px;
      border: 2px solid #e9e9e9;
      background-color: #fff;
      cursor: pointer;
      border-radius: 50%;
      vertical-align: middle;
      &-active {
        border-color: rgba(45, 183, 245, 0.5);
      }
      &-reverse {
        margin-right: -4px;
      }
    }
  
    &-disabled {
      background-color: #e9e9e9;
  
      .rc-slider-track {
        background-color: #ccc;
      }
  
      .rc-slider-handle, .rc-slider-dot {
        border-color: #ccc;
        box-shadow: none;
        background-color: #fff;
        cursor: not-allowed;
      }
  
      .rc-slider-mark-text, .rc-slider-dot {
        cursor: not-allowed!important;
      }
    }
  
  }
}

@media screen and (max-width: 575px) {
  .time-bar-container {
    width: calc(100% - 100px);
  }

  .time-bar-container .rc-slider-mark {
    font-size: 11px;
  }
}