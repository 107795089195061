div#Hero {
    // background-image: url("assets/heroImg-af-main.jpg");
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;

    position: relative;
    background-color: var(--primary-color);
    min-height: 50vh !important;
    margin-top: 8rem;
    height: auto !important;

    // margin-top: 12rem;

    display: flex;
    justify-content:center;
    align-items:center;
    text-align: center;

    h1 {
        strong {
            position: relative;
            z-index: 1;
            img {
                position: absolute;
                left: 0;
                bottom: -0.25rem;
                z-index: -1;
            }
        }
    }

    .i-arrow {
        margin-left: 0.3rem;
    }

    div.container {
        p {
            font-size: 18px;
        }
        div.hero-btns {
            margin-top: 1.5rem;
            padding-bottom: 2rem;
            a:last-child {
                margin: 0 0.6rem;
            }
        }
    }

    .hero-d-left {
        position: absolute;
        left: 0;
        top: 30%;
    }
    .hero-d-right {
        position: absolute;
        right: 0;
        top: 15%;
    }

    
}
#hero-mockup {
    div {
        img {
            border-radius: 8px;
            box-shadow: -14px 16px 99px rgba(0,0,0,.05);
        }
    }   
}

div.container.extended {
    max-width: 80% !important;
    // margin-top: -12rem;
    position: relative;
    z-index: 1;
}

