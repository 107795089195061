div#myAccount {
    // margin-top: 14rem;

    form.card {
        max-width: 600px;
        border: none !important;
        border-radius: 10px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, .05);
        padding: 1rem 1.5rem 2rem 1.5rem;

        margin-top: 4.5rem;


        .save-button {
            min-width: 186px;
        }
    }

    div.grade-item {
        background: #f3f3f3;
        padding: 1rem 1.5rem;
        border-radius: 5px;

        p {
            margin: 1rem 0 !important;
        }
    }

    div.account-navigation {
        padding-right: 3rem;
    }

    .btns-group {
        a:nth-of-type(2) {
            margin: 0 1rem;
        }
    }

    #myaccount-avatar {
        border-radius: 50% !important;
        min-width: 125px;
        min-height: 125px;
        font-size: 30px;
        position: absolute;
        transform: translate(0, -3rem);
        background: #F4F5F5 !important;
        color: var(--text-color) !important;
        box-shadow: 10px 10px 16px rgba(0, 0, 0, .05) inset, -6px 7px 18px rgba(0, 0, 0, .02) !important;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .avatar-icon {
        border-radius: 50% !important;
        min-width: 125px;
        min-height: 125px;
        font-size: 30px;
        position: absolute;
        transform: translate(0, -3rem);
        background: #F4F5F5 !important;
        color: var(--text-color) !important;
        box-shadow: 10px 10px 16px rgba(0, 0, 0, .05) inset, -6px 7px 18px rgba(0, 0, 0, .02) !important;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 125px;
    }
    
    .avatar-icon-image {
        transform: translate(-50%, -50%);
        border-radius: 50% !important;
        min-width: 125px;
        min-height: 125px;
        font-size: 30px;
        position: absolute;
        background: #F4F5F5 !important;
        color: var(--text-color) !important;
        box-shadow: 10px 10px 16px rgba(0, 0, 0, .05) inset, -6px 7px 18px rgba(0, 0, 0, .02) !important;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 125px;
    }

    

    .upload-icon {
        position: absolute;
        transform: translate(5rem, 1.5rem);
    }

    .full-name {
        position: relative;
        left: 2rem;
    }

    #file-upload,
    #file-upload::-webkit-file-upload-button {
        /* chromes and blink button */
        position: absolute;
        z-index: 1;
        padding: 3.2rem;
        transform: translate(0, -2.9rem);
        opacity: 0;
        cursor: pointer !important;
    }

}
