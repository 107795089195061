div#Footer {
    padding: 5rem 0;
    img {
        max-width: 210px;
        max-height: 53px;
    }
    p.copyright {
        font-size: 16px;
    }
    div.footer-btns {
        margin-top: 1.5rem;
        padding-bottom: 2rem;
        a:last-child {
            margin: 0 0.6rem;
        }
    }

    .i-arrow {
        margin-left: 0.3rem;
    }
}