#createStudent {
    form.card {
		max-width: 600px;
		border: none !important;
		border-radius: 10px;
		box-shadow: 0px 8px 15px rgba(0,0,0,.05);
        padding: 0 1.5rem 2rem 1.5rem;

        margin-top: 3rem;
    }
}