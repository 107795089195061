// LMS / Courses
div#LMS {
	position: relative;
    div.lms-cards {
		img.card-img-top {
			// padding-top: 0.5rem;
			// min-height: 233px;
		}
        div.card {
			max-width: 350px;
			border: none !important;
			border-radius: 10px;
			box-shadow: 0px 8px 15px rgba(0,0,0,.05);
		}
		div.card-body {
			
		}
    }

	.i-arrow-b-black {
		position: relative;
		bottom: 0.05rem;
        margin-right: 0.3rem;
		transform: rotate(180deg);
	}

	.i-arrow-b-grey {
		position: relative;
		bottom: 0.05rem;
        margin-right: 0.3rem;
		transform: rotate(180deg);
	}

	.i-arrow {
		position: relative;
		bottom: 0.05rem;
        margin-left: 0.3rem;
    }

    .lms-content {
		line-height: 1.8em;
	}

	.lms-content h1 {
		margin-top: 1em;
	}

	.lms-content h2 {
		margin-top: 1em;
	}

	.lms-content h3 {
		margin-top: 0.8em;
	}

	.lms-content h4 {
		margin-top: 0.6em;
	}

	.card-title {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		line-height: 1.5;
	}

	div#courses, div#course {
		// div.lms-cards {
		// 	div.card {
		// 		transition: .35s;
		// 		mix-blend-mode: luminosity;
		// 	&:hover {
		// 		transition: .35s;
		// 		mix-blend-mode: normal;
		// 		transform: translateY(-0.25rem);
		// 	}
		// 	}
		// }
	}

	// Home Route
	#home-cards {
		div.lms-cards {
			margin: 0 0.4rem;
		}
		img.p-1 {
			padding: 1rem !important;
		}
		.i-check-green {
			max-width: 30px !important;
			margin-right: 1rem;
			position: absolute;
			transform: translate(-1.25rem, -0.15rem);
			opacity: 1 !important;
		}
		.home-course-completed {
			position: relative;
			left: 1.25rem;
		}
	}

	// Quiz Routes
	#quiz-results-card {
		.quiz-passed-icon {
			padding: 2rem 0;
		}
		.badge {
			border-radius: 50px !important;
			padding: 0.5rem 2rem !important;
			position: absolute;
			right: 0;
			transform: translate(2rem, -1rem) rotate(7deg);
		}
	}

	.end-result {
		h5 {
			font-size: 18px;
			color: var(--text-color);
		}
	}

	// Courses/Articles/Lessons
	.Article {
		padding-right: 5rem;
	}
}
div#LMS_notLogged {
	margin-top: 12rem;
}